import { CircularProgress, Flex } from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { chatAIServices } from 'services'

export const IframeMilize: FC = () => {
  const [dataIframe, setDataIframe] = useState<{
    url: string
    loading: boolean
  }>({ url: '', loading: false })

  const getUrlIframe = useCallback(async () => {
    try {
      setDataIframe(prev => ({ ...prev, loading: true }))
      const { app_url } = await chatAIServices.getUrlIframe()
      setDataIframe(prev => ({ ...prev, loading: false, url: app_url }))
    } catch (error) {
      setDataIframe(prev => ({ ...prev, loading: false, url: '' }))
    }
  }, [])

  useEffect(() => {
    getUrlIframe()
  }, [getUrlIframe])

  if (dataIframe.loading) {
    return (
      <Flex
        alignItems={'center'}
        h={'calc(100vh - 145px)'}
        justifyContent={'center'}
        w={'full'}
      >
        <CircularProgress isIndeterminate color="blue.900" size={10} />
      </Flex>
    )
  }

  return (
    <>
      <iframe
        src={dataIframe.url}
        style={{ height: 'calc(100vh - 145px)', width: '100%' }}
        title="専用ツール"
      />
    </>
  )
}

import { Box, Flex, Text } from '@chakra-ui/layout'
import { SelectField } from 'components/atoms'
import { FormikProvider } from 'formik'
import React, { FC } from 'react'
import { Switch } from 'components/fromAdvisor/elements/Switch'
import { CircularProgress, SimpleGrid } from '@chakra-ui/react'
import { Button } from 'components/fromAdvisor/elements'
import { Option } from 'types/conmon'
import CustomMultiSelect from 'components/atoms/MultiSelectField/CustomMultiSelect'
import WordCloud from '../WordCloud'
// import Echarts from '../Echarts'
import { ModalZoom } from '../WordMap/ModalZoom'
import ChartWrapper from '../ChartWrapper'
import RenderActionOpenDrawer from './RenderActionOpenDrawer'
import { NewsDetailWordMapProps, useNewsDetailWordMap } from './hook'
import ExplanationAi from './ExplanationAi'

const NewsDetailWordMap: FC<NewsDetailWordMapProps> = props => {
  const {
    isOpenDrawer,
    formik,
    boxRef,
    optionsAnalysisMenu,
    setFieldValue,
    values,
    typeNews,
    dataChart,
    widthChart,
    multiCompanyOptions,
    valueSwitch,
    handleSwitch,
    dataChartMore,
    handleResetForm,
    valueExplanation,
    handleOffSwitch,
    infoModal,
    isZoom,
    handleCloseZoom,
    handleOpenZoom,
    isNotWordmap,
    reportType,
    containerChartHeight,
    containerChartWidth,
  } = useNewsDetailWordMap(props)
  return (
    <FormikProvider value={formik}>
      <Box ref={boxRef} p={'12px 16px'} w={'full'}>
        <Flex
          align={'center'}
          gap={'12px'}
          justify={'space-between'}
          pb={'12px'}
        >
          <Flex gap="16px" w={'calc(100% - 204px)'}>
            <SelectField
              height="40px"
              name="analysis_menu"
              options={optionsAnalysisMenu}
              placeholder="--"
              valueOption={optionsAnalysisMenu.find(
                it => it.value === values.analysis_menu,
              )}
              width="auto"
              onChange={e => handleResetForm(e)}
            />
            {values.analysis_menu === '1' && (
              <CustomMultiSelect
                defaultValue={values.multi_company}
                labelTooltip="項目は２つまで選択可能です"
                options={multiCompanyOptions}
                placeholder="企業"
                setValue={(options: Option[]) =>
                  setFieldValue('multi_company', options)
                }
              />
            )}
            {typeNews && !isOpenDrawer && values.analysis_menu === '2' && (
              <RenderActionOpenDrawer
                companyId={props.companyId}
                handleOffSwitch={handleOffSwitch}
                isOpenDrawer={isOpenDrawer}
                quarter={props.quarter ?? 0}
                reportType={reportType}
                setFieldValue={setFieldValue}
                values={values}
                year={props.year}
              />
            )}
          </Flex>
          <Flex align={'center'} gap={'6px'} w={'204px'}>
            <Text
              fontSize="14px"
              fontWeight="normal"
              lineHeight="14px"
              minW="fit-content"
            >
              AI解説
            </Text>
            <Switch
              cusbgcolor={'#0084FF'}
              isChecked={valueSwitch}
              onChange={() => handleSwitch()}
            />
            <Button
              _hover={{
                bgColor: 'white.50',
              }}
              bgColor="white.50"
              border="solid 1px"
              borderColor="blue.900"
              borderRadius="12px"
              color="blue.900"
              fontSize="sm"
              fontWeight="bold"
              h="40px"
              isDisabled={isNotWordmap}
              lineHeight="shorter"
              padding="8px 16px"
              text="全画面表示"
              onClick={() => {
                handleOpenZoom({ isShowAll: true, typeChart: '', index: null })
              }}
            />
          </Flex>
        </Flex>
        {typeNews && isOpenDrawer && values.analysis_menu === '2' && (
          <RenderActionOpenDrawer
            companyId={props.companyId}
            handleOffSwitch={handleOffSwitch}
            isOpenDrawer={isOpenDrawer}
            quarter={props.quarter}
            reportType={reportType}
            setFieldValue={setFieldValue}
            values={values}
            year={props.year}
          />
        )}

        {valueSwitch && (
          <ExplanationAi
            showContent={valueSwitch}
            valueExplanation={valueExplanation}
          />
        )}
        {isNotWordmap && !dataChart.loading && (
          <Text
            color={'black.100'}
            fontSize={'16px'}
            textAlign={'center'}
            w="full"
          >
            データがありません。
          </Text>
        )}
        {dataChart.loading && (
          <Flex alignItems={'center'} h={'150px'} justify={'center'} w={'full'}>
            <CircularProgress isIndeterminate color="blue.900" size={10} />
          </Flex>
        )}
        {values.analysis_menu === '0' && !isNotWordmap && (
          <SimpleGrid columns={1} spacingX="16px" spacingY="4px">
            <Box
              w={
                boxRef.current
                  ? isOpenDrawer
                    ? `calc(${widthChart - 32})`
                    : `calc(${widthChart - 40} / 2)`
                  : '100%'
              }
            >
              <Flex align={'center'} justify={'space-between'}>
                <ChartWrapper
                  handleOpenZoom={() => {
                    handleOpenZoom({
                      isShowAll: false,
                      typeChart: 'wordcloud',
                      index: 0,
                    })
                  }}
                  isBorderLeft={true}
                  isShowButtonZoom={false}
                  label="ワードマップ"
                />
              </Flex>
              <Box
                height={`${containerChartHeight}px`}
                width={`${containerChartWidth}px`}
              >
                <WordCloud
                  height={containerChartHeight}
                  width={containerChartWidth}
                  words={dataChart.wordLesChart.dataWordCloud}
                />
              </Box>
            </Box>
            {/* {Boolean(!dataChart.wordLesChart.dataEchart.length) ? (
              <></>
            ) : dataChart.loading ? (
              <Box
                bgColor={'#FEFEFE'}
                borderRadius={'16px'}
                h={'396px'}
                mb="8px"
                w={'full'}
              ></Box>
            ) : (
              <Box
                w={
                  boxRef.current
                    ? isOpenDrawer
                      ? `calc(${widthChart - 32})`
                      : `calc(${widthChart - 40} / 2)`
                    : '100%'
                }
              >
                <Flex align={'center'} justify={'space-between'}>
                  <ChartWrapper
                    handleOpenZoom={() => {
                      handleOpenZoom({
                        isShowAll: false,
                        typeChart: 'echart',
                        index: null,
                      })
                    }}
                    isBorderLeft={true}
                    label="共起ネットワーク"
                  />
                </Flex>
                <Box h={550}>
                  <Echarts
                    ref={el => (ref.current[1] = el)}
                    data={dataChart.wordLesChart.dataEchart}
                    reloadChart={reloadChart}
                  />
                </Box>
              </Box>
            )} */}
          </SimpleGrid>
        )}
        {values.analysis_menu !== '0' && !isNotWordmap && (
          <SimpleGrid
            columns={isOpenDrawer ? 1 : 2}
            spacingX="16px"
            spacingY="4px"
          >
            {(values.analysis_menu === '1'
              ? dataChart.dataListWordCloud.data
              : dataChart.dataTimeLine.data
            ).map((item, index) => (
              <Box key={index}>
                <Flex align={'center'} justify={'space-between'}>
                  <ChartWrapper
                    handleOpenZoom={() => {
                      handleOpenZoom({
                        isShowAll: false,
                        typeChart: 'wordcloud',
                        index: index,
                      })
                    }}
                    isBorderLeft={true}
                    label={item.title}
                  />
                </Flex>
                <Box
                  height={`${containerChartHeight}px`}
                  width={`${containerChartWidth}px`}
                >
                  <WordCloud
                    height={containerChartHeight}
                    width={containerChartWidth}
                    words={item.words_cloud}
                  />
                </Box>
              </Box>
            ))}
            {dataChartMore.data.map((item, index) => (
              <Box key={index}>
                <Flex align={'center'} justify={'space-between'}>
                  <ChartWrapper
                    handleOpenZoom={() => {
                      handleOpenZoom({
                        isShowAll: false,
                        typeChart: 'wordcloud',
                        index: dataChart.dataListWordCloud.data.length + index,
                      })
                    }}
                    isBorderLeft={true}
                    label={item.title}
                  />
                </Flex>
                <Box
                  height={`${containerChartHeight}px`}
                  width={`${containerChartWidth}px`}
                >
                  <WordCloud
                    height={containerChartHeight}
                    width={containerChartWidth}
                    words={item.words_cloud}
                  />
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        )}
      </Box>
      <ModalZoom
        analysisChart={values.analysis_menu}
        dataChart={dataChart}
        dataChartMore={dataChartMore}
        height={containerChartHeight}
        infoModal={infoModal}
        isOpen={isZoom}
        width={containerChartWidth}
        onClose={handleCloseZoom}
      />
    </FormikProvider>
  )
}

export default NewsDetailWordMap

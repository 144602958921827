import { Box, Flex } from '@chakra-ui/react'
import ChartWrapper from 'components/molecules/ChartWrapper'
import WordCloud from 'components/molecules/WordCloud'
import React, { FC } from 'react'
import { WordCloudType } from 'services/companyServices'

type WordMapChartProps = {
  key: number
  handleOpenZoom: () => void
  data: {
    title: string
    words_cloud: WordCloudType[]
  }
  width: number
  height: number
  keySearch?: string
  isShowButtonZoom?: boolean
}

const WordMapChart: FC<WordMapChartProps> = ({
  key,
  handleOpenZoom,
  data,
  width,
  height,
  keySearch,
  isShowButtonZoom = true,
}) => {
  return (
    <Box key={key} w={'100%'}>
      <Flex align={'center'} justify={'space-between'}>
        <ChartWrapper
          handleOpenZoom={handleOpenZoom}
          isShowButtonZoom={isShowButtonZoom}
          label={data.title}
        />
      </Flex>
      <Box
        bgColor={'#FEFEFE'}
        borderRadius={'12px'}
        height={`${height}px`}
        width={`${width}px`}
      >
        <WordCloud
          height={height}
          keySearch={keySearch}
          width={width}
          words={data.words_cloud}
        />
      </Box>
    </Box>
  )
}

export default WordMapChart

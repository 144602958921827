/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, ReactElement, useMemo } from 'react'
import { Modal } from 'components/fromAdvisor/elements'
import { WordCloudType } from 'services/companyServices'
import { Box, Flex, Text } from '@chakra-ui/react'
import { DataChartType } from 'types/chart'
import WordCloud from '../WordCloud'
// import Echarts from '../Echarts'
/**
 * ModalZoom component page.
 * @return {JSX.Element} ModalZoom component.
 */
export const ModalZoom: FC<{
  isOpen: boolean
  onClose: () => void
  dataChart: DataChartType
  analysisChart: string
  dataChartMore: {
    loading: boolean
    data: {
      title: string
      words_cloud: WordCloudType[]
    }[]
  }
  width: number
  height: number
  infoModal: { isShowAll: boolean; typeChart: string; index: number | null }
}> = ({
  isOpen,
  onClose,
  dataChart,
  analysisChart,
  dataChartMore,
  width,
  height,
  infoModal,
}): ReactElement => {
  const dataWordCloud = useMemo(() => {
    switch (analysisChart) {
      case '0':
        return [
          {
            words_cloud: dataChart.wordLesChart.dataWordCloud,
            title: '重要なワードを見える化',
          },
        ]
      case '1':
        return [...dataChart.dataListWordCloud.data, ...dataChartMore.data]
      case '2':
        return dataChart.dataTimeLine.data

      default:
        return []
    }
  }, [
    analysisChart,
    dataChart.dataListWordCloud,
    dataChart.dataTimeLine,
    dataChart.wordLesChart.dataWordCloud,
    dataChartMore.data,
  ])

  const modalHeight = window.innerHeight - 100
  const modalWidth = window.innerWidth - 100
  const scaleToHeight = (modalHeight - 32) / height
  const scaleToWidth = modalWidth / width
  const isScaleToHeight = height * scaleToWidth > modalHeight - 32
  const scale = isScaleToHeight ? scaleToHeight : scaleToWidth

  const conditionShowEchart =
    (infoModal.isShowAll && !infoModal.typeChart && analysisChart === '0') ||
    infoModal.typeChart === 'echart'

  const conditionShowWordCloud =
    infoModal.isShowAll || infoModal.typeChart === 'wordcloud'

  const renderWordCloud = infoModal.isShowAll
    ? dataWordCloud
    : dataWordCloud.filter((item, index) => index === infoModal.index)

  return (
    <Modal
      isShowIconClose
      autoFocus={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      maxHeight="fit-content"
      maxWidth={'fit-content'}
      onClose={() => {
        onClose()
      }}
    >
      <Box
        bgColor={'#FEFEFE'}
        borderRadius={4}
        maxH={isScaleToHeight ? `${modalHeight}px` : `${height * scale + 32}px`}
        overflowY={'auto'}
        w={'full'}
      >
        {conditionShowWordCloud &&
          renderWordCloud.map((item, index) => {
            return (
              <Flex
                key={index}
                alignItems={'center'}
                direction={'column'}
                h={`${height * scale + 32}px`}
                w={`${width * scale}px`}
              >
                <Box w={'full'}>
                  <Text
                    fontSize="18px"
                    fontWeight="bold"
                    lineHeight="shorter"
                    mb={'8px'}
                    minW="fit-content"
                    px="8px"
                  >
                    {item.title}
                  </Text>
                </Box>
                <Box
                  borderRadius={'12px'}
                  h={`${height}px`}
                  transform={`scale(${scale})`}
                  transformOrigin={'top'}
                  w={`${width}px`}
                >
                  <WordCloud
                    height={height}
                    width={width}
                    words={item.words_cloud}
                  />
                </Box>
              </Flex>
            )
          })}
        {/* {conditionShowEchart && (
          <Flex
            alignItems={'center'}
            direction={'column'}
            h={height * scale + 32}
            w={width * scale}
          >
            <Box w={'full'}>
              <Text
                fontSize="18px"
                fontWeight="bold"
                lineHeight="shorter"
                mb={'8px'}
                minW="fit-content"
                px="8px"
              >
                各ワード同士の繋がり
              </Text>
            </Box>
            <Box
              borderRadius={'12px'}
              h={height}
              transform={`scale(${scale})`}
              transformOrigin={'top'}
              w={width}
            >
              <Echarts data={dataChart.wordLesChart.dataEchart} />
            </Box>
          </Flex>
        )} */}
      </Box>
    </Modal>
  )
}

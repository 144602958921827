import {
  Box,
  Center,
  Flex,
  Image,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import { AxiosError } from 'axios'
import { DataTable, InputField } from 'components/atoms'
import { Button } from 'components/fromAdvisor/elements/Button'
import { Checkbox } from 'components/fromAdvisor/elements/CheckBox'
import { ModalConfirm } from 'components/molecules'
import { ADMIN_ROUTES, ROLE, icons } from 'constant'
import { FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { userAdminServices } from 'services'
import { UserResponse } from 'services/userServices'
import { PaginateResponse } from 'types/conmon'
import { TextOverflowTooltip } from 'components/fromAdvisor/elements'
import { DeleteIcons } from 'components/atoms/Icons'
import { useSelector } from 'react-redux'
import { auth } from 'context/Auth/auth.selector'
import { MESSAGE_USER } from '../UserRegistration/validation'

export const userHasLimitedRights = [
  'newsad-test@milize.co.jp',
  'newsad_yokohama@milize.co.jp',
  'newsad_cosmo@milize.co.jp',
]

/**
 * UserList Page
 * @constructor
 */
export const UserListPage: FC = () => {
  const navigate = useNavigate()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const toast = useToast()
  const userLogin = useSelector(auth)

  const isRestrictUserRights = userHasLimitedRights.includes(
    userLogin?.email ?? '',
  )

  const formik = useFormik({
    initialValues: { user_search: '' },
    /**
     *
     */
    async onSubmit() {},
  })
  const { setFieldValue } = formik

  const columnHelper = createColumnHelper<UserResponse>()

  const [selectRecordId, setSelectRecordId] = useState<number[]>([])
  const [userList, setUserList] = useState<{
    loading: boolean
    data: UserResponse[]
    last_updated?: string
  }>({ loading: false, data: [] })
  const [pagination, setPagination] = useState<PaginateResponse>({
    page_size: 15,
    page: 1,
    page_count: 1,
    total_count: 0,
  })
  const [searchUser, setSearchUser] = useState<string>('')

  const fetchDataUserList = useCallback(
    async (page?: number) => {
      try {
        setUserList(prev => ({ ...prev, loading: true }))
        setSelectRecordId([])
        const params = {
          page_size: pagination.page_size,
          page: page || pagination.page,
          sort_by: 'updated_at:desc',
          search_key: searchUser,
        }
        const res = await userAdminServices.getListUser(params)
        setUserList(prev => ({
          ...prev,
          data: res.data,
          loading: false,
          last_updated: res.last_updated,
        }))
        if (res.paginate) {
          setPagination(res.paginate)
        }
      } catch (error) {
        setUserList(prev => ({ ...prev, loading: false }))
        if (error instanceof AxiosError) {
          console.log('error', error)
        }
      }
    },
    [pagination.page, pagination.page_size, searchUser],
  )

  useEffect(() => {
    fetchDataUserList()
  }, [fetchDataUserList])

  /**
   * handle click checkbox
   */
  const handleSelectRecord = (id: number) => {
    const idExist = selectRecordId.findIndex(r => r === id)
    if (idExist === -1) return setSelectRecordId(prev => [...prev, id])
    const newSelectRecordId = [...selectRecordId].filter(r => r !== id)
    return setSelectRecordId(newSelectRecordId)
  }

  /**
   * handle click checkbox all
   */
  const handleSelectRecordAll = () => {
    if (selectRecordId.length === userList.data.length)
      return setSelectRecordId([])
    const newSelectRecordId = userList.data.map(u => u.user_id)
    return setSelectRecordId(newSelectRecordId)
  }

  /**
   * handle click checkbox all
   */
  const handleCancelSelected = () => {
    setSelectRecordId([])
  }

  /**
   * handle click checkbox all
   */
  const handleDeleteMultiRecord = async () => {
    if (!selectRecordId) return
    try {
      await userAdminServices.removeUser(selectRecordId)
      setSelectRecordId([])
      fetchDataUserList(1)
      toast({
        status: 'success',
        description: MESSAGE_USER.DELETE_USER,
        position: 'top',
      })
      onClose()
    } catch (error: any) {
      toast({
        status: 'error',
        description: error?.response?.data?.message,
        position: 'top',
      })
    }
  }

  const columns = [
    columnHelper.accessor('user_id', {
      id: 'user_id',
      size: 48,
      meta: {
        minWidthColumn: '48px',
      },
      cell: info => {
        const checked = selectRecordId.find(
          recordId => recordId === info.row.original.user_id,
        )
        return (
          <Center>
            <Checkbox
              isChecked={checked !== undefined ? true : false}
              onChange={() => {
                handleSelectRecord(info.row.original.user_id)
              }}
            />
          </Center>
        )
      },
      header: () => {
        return (
          <Center>
            <Checkbox
              isChecked={
                selectRecordId.length &&
                selectRecordId.length === userList.data.length
                  ? true
                  : false
              }
              isDisabled={!userList.data.length}
              onChange={handleSelectRecordAll}
            />
          </Center>
        )
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      cell: info => {
        const userId = info.row.original.user_id
        const firstName = info.row.original.first_name
        const lastName = info.row.original.last_name
        const kana_first_name = info.row.original.furigana_name_first
        const kana_last_name = info.row.original.furigana_name_last
        return (
          <Text
            color="blue.900"
            cursor={'pointer'}
            fontSize="sm"
            fontWeight="700"
            lineHeight="14px"
            onClick={() => navigate(ADMIN_ROUTES.USER_DETAIL + `/${userId}`)}
          >
            {`${firstName} ${lastName}（${kana_first_name}${kana_last_name}）`}
          </Text>
        )
      },
      header: 'ユーザー名',
      size: 258,
      meta: {
        minWidthColumn: '258px',
      },
    }),
    columnHelper.accessor('department', {
      id: 'department',
      cell: info => (
        <Text fontSize="sm" fontWeight="normal" lineHeight="14px">
          {info.getValue()}
        </Text>
      ),
      header: '部署',
      size: 239,
      meta: {
        minWidthColumn: '239px',
      },
    }),
    columnHelper.accessor('email', {
      id: 'email',
      cell: info => (
        <TextOverflowTooltip
          color="black.100"
          fontSize="sm"
          fontWeight="normal"
          value={info.getValue()}
        />
      ),
      header: 'メールアドレス',
      size: 489,
      meta: {
        minWidthColumn: '489px',
      },
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      cell: info => (
        <Text fontSize="sm" fontWeight="normal" lineHeight="14px">
          {moment(info.getValue()).format('YYYY/MM/DD')}
        </Text>
      ),
      header: '登録日',
      size: 100,
      meta: {
        minWidthColumn: '100px',
      },
    }),
    columnHelper.accessor('user_type', {
      id: 'user_type',
      cell: info => (
        <Text
          color="black.100"
          fontSize="sm"
          fontWeight="normal"
          lineHeight="14px"
        >
          {info.getValue() === ROLE.ADMIN ? '管理者' : '一般ユーザー'}
        </Text>
      ),
      header: '権限',
      size: 119,
      meta: {
        minWidthColumn: '119px',
      },
    }),
  ]

  return (
    <FormikProvider value={formik}>
      <Box
        bgColor="white.50"
        borderRadius={4}
        minH="calc(100vh - 88px)"
        w="100%"
      >
        <Box padding="12px 24px">
          <Flex justifyContent="space-between">
            <Flex alignItems="center" gap="24px">
              <Text
                fontSize="24px"
                fontWeight="700"
                letterSpacing="-0.48px"
                lineHeight="40px"
                minW="158px"
                padding="0 8px"
              >
                ユーザー一覧
              </Text>
              <InputField
                height="40px"
                name="user_search"
                placeholder="ユーザー検索"
                width="242px"
                onChange={e => {
                  setFieldValue('user_search', e.target.value)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setSearchUser(formik.values.user_search.trim())
                    setPagination(prev => ({ ...prev, page: 1 }))
                  }
                }}
              />
              <Text
                fontSize="12px"
                fontWeight="400"
                lineHeight={'12px'}
                minW="135px"
                opacity="0.5"
              >
                最終更新日：
                {moment(userList?.last_updated).format('YYYY/MM/DD')}
              </Text>
            </Flex>
            <Box>
              <Button
                backgroundColor="#FEFEFE"
                border="1px solid #0084FF"
                borderRadius="12px"
                color="#0084FF"
                fontSize={'14px'}
                fontWeight={700}
                height="40px"
                leftIcon={<Image src={icons.ADD_USER} />}
                letterSpacing={'-0.02px'}
                padding={'8px 16px'}
                text="新規ユーザー登録"
                width={'174px'}
                onClick={() => navigate(ADMIN_ROUTES.USER_REGISTRATION)}
              />
            </Box>
          </Flex>
          <Box marginTop="16px">
            <DataTable
              columns={columns}
              data={userList.data}
              height={
                selectRecordId.length
                  ? 'calc(100vh - 296px)'
                  : 'calc(100vh - 216px)'
              }
              isPagination={true}
              isScroll={true}
              isShowAction={false}
              loadingData={userList.loading}
              maxH={
                selectRecordId.length
                  ? 'calc(100vh - 296px)'
                  : 'calc(100vh - 216px)'
              }
              pagination={pagination}
              rangePage={[15, 30, 50]}
              setPagination={setPagination}
              onCancel={handleCancelSelected}
              onDelete={onOpen}
            />
            <ModalConfirm
              isKeepAll
              isOpen={isOpen}
              title={`このユーザーは削除され、\n
              ログインできなくなります。\n
              よろしいですか？`}
              onClose={onClose}
              onSubmit={handleDeleteMultiRecord}
            />
          </Box>
        </Box>
        {Boolean(selectRecordId.length) && (
          <Box
            backdropFilter="blur(4px)"
            background="rgba(0, 0, 0, 0.10)"
            bottom={userList.data.length <= 15 ? '24px' : '0'}
            display="flex"
            gap="16px"
            justifyContent="end"
            left="0"
            padding="16px 48px"
            position={userList.data.length <= 15 ? 'static' : 'sticky'}
            right="0"
            w="100%"
          >
            <Button
              backgroundColor="#0084FF"
              border="1px solid #0084FF"
              borderRadius="12px"
              color="white.50"
              height="48px"
              isDisabled={isRestrictUserRights}
              leftIcon={<DeleteIcons />}
              text="このユーザーを削除する"
              width="253px"
              onClick={onOpen}
            />
            <Button
              backgroundColor="#FEFEFE"
              border="1px solid #0084FF"
              borderRadius="12px"
              color="#0084FF"
              height="48px"
              text="全てのチェックを外す"
              width="206px"
              onClick={handleCancelSelected}
            />
          </Box>
        )}
      </Box>
    </FormikProvider>
  )
}

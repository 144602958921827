import { useFormik } from 'formik'
import { Option } from 'types/conmon'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { companyServices, storageServices } from 'services'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useDisclosure } from '@chakra-ui/react'
import { DataChartMoreType, DataChartType, InfoModalType } from 'types/chart'
import {
  WordCategoryChartResponse,
  WordLesChartResponse,
  WordTimeLineChartResponse,
} from 'services/companyServices'

export type WordMapProps = {}

export interface FormValues {
  analysis_menu: string
  targetYear: string
  targetDocument: string
  quarters: string
  display_size: string
  number_of_word: string
  term: string
  search_key: string
  multi_company: Option[]
  sort_by: number
}

export const useWordMap = (props: WordMapProps) => {
  const { id } = useParams()
  const abortControllerRef = useRef<AbortController>(new AbortController())
  const accessToken = storageServices.getAccessToken()

  const formik = useFormik<FormValues>({
    initialValues: {
      analysis_menu: '0',
      targetYear: '',
      targetDocument: '',
      quarters: '',
      display_size: '0',
      number_of_word: '2',
      term: '',
      search_key: '',
      multi_company: [],
      sort_by: 1,
    },
    /**
     *
     */
    onSubmit() {},
  })

  const { values, setFieldValue, setValues } = formik
  const [companyName, setCompanyName] = useState('')
  const [multiCompanyOptions, setMultiCompanyOptions] = useState<Option[]>([])
  const [valueSwitch, setValueSwitch] = useState(false)
  const {
    isOpen: isZoom,
    onOpen: onOpenZoom,
    onClose: onCloseZoom,
  } = useDisclosure()

  const [dataChart, setDataChart] = useState<DataChartType>({
    wordLesChart: {
      dataWordCloud: [],
      dataEchart: [],
      hashed_data: '',
    },
    dataListWordCloud: {
      data: [],
      hashed_data: '',
    },
    dataTimeLine: {
      data: [],
      hashed_data: '',
    },
    loading: false,
  })

  const [dataChartMore, setDataChartMore] = useState<DataChartMoreType>({
    loading: false,
    data: [],
    hashed_data: '',
  })

  const [responseDataWordCloud, setResponseDataWordCloud] = useState<{
    option1: WordLesChartResponse | null
    option2: WordCategoryChartResponse | null
    option3: WordTimeLineChartResponse[]
  }>({
    option1: null,
    option2: null,
    option3: [],
  })

  const [infoModal, setInfoModal] = useState<InfoModalType>({
    isShowAll: false,
    typeChart: '',
    index: null,
  })

  const [valueExplanation, setValueExplanation] = useState<{
    loading: boolean
    data: string
    done: boolean
  }>({ loading: false, data: '', done: true })

  const [windowWidthSize, setWindowWidthSize] = useState(0)

  const isNotWordmap = React.useMemo(
    () =>
      !dataChart.wordLesChart.dataEchart.length &&
      !dataChart.dataListWordCloud.data.length &&
      !dataChart.dataTimeLine.data.length &&
      !dataChart.wordLesChart.dataWordCloud.length &&
      !dataChartMore.data.length,
    [
      dataChart.dataListWordCloud.data.length,
      dataChart.dataTimeLine.data.length,
      dataChart.wordLesChart.dataEchart.length,
      dataChart.wordLesChart.dataWordCloud.length,
      dataChartMore.data.length,
    ],
  )

  const displaySizeChart = React.useMemo(() => {
    switch (values.display_size) {
      case '0':
        return 2
      case '1':
        return 3
      case '2':
        return 5

      default:
        return 2
    }
  }, [values.display_size])

  const gapChart = React.useMemo(
    () => (values.analysis_menu === '0' ? 0 : 16 * (displaySizeChart - 1)),
    [displaySizeChart, values.analysis_menu],
  )

  const containerChartWidth = React.useMemo(() => {
    const width =
      values.analysis_menu === '0'
        ? windowWidthSize - 96 - gapChart
        : (windowWidthSize - 96 - gapChart) / displaySizeChart

    return Math.floor(width)
  }, [displaySizeChart, gapChart, values.analysis_menu, windowWidthSize])

  const containerChartHeight = React.useMemo(
    () => Math.floor(containerChartWidth / 1.58),
    [containerChartWidth],
  )

  const isExplanation = React.useMemo(() => {
    if (values.analysis_menu === '2') {
      if (['2', '5'].includes(values.targetDocument)) {
        if (!values.targetYear || !values.term) return false
      } else {
        if (!values.targetYear || !values.quarters || !values.term) return false
      }
    } else {
      if (['2', '5'].includes(values.targetDocument)) {
        if (!values.targetYear) return false
      } else {
        if (!values.targetYear || !values.quarters) return false
      }
    }

    return true
  }, [
    values.analysis_menu,
    values.quarters,
    values.targetDocument,
    values.targetYear,
    values.term,
  ])

  const fileType = useMemo(() => {
    switch (values.analysis_menu) {
      case '0':
        return 'file_path'
      case '1':
        return 'company_industry'
      case '2':
        return 'company_year'
      default:
        return 'file_path'
    }
  }, [values.analysis_menu])

  const fetchCompanyInfo = useCallback(async () => {
    try {
      if (!id) return
      const { data } = await companyServices.getCompanyInfo({
        company_id: id,
        mkt_date: moment().format('YYYY-MM-DD'),
      })
      setCompanyName(data.company_name)
    } catch (error) {}
  }, [id])

  const hashedDataChart = useMemo(() => {
    switch (values.analysis_menu) {
      case '0':
        return dataChart.wordLesChart.hashed_data

      case '1':
        return (
          dataChart.dataListWordCloud.hashed_data + dataChartMore.hashed_data
        )

      case '2':
        return dataChart.dataTimeLine.hashed_data

      default:
        return ''
    }
  }, [
    dataChart.dataListWordCloud.hashed_data,
    dataChart.dataTimeLine.hashed_data,
    dataChart.wordLesChart.hashed_data,
    dataChartMore.hashed_data,
    values.analysis_menu,
  ])

  const payloadSendWebsocket = useMemo(() => {
    let payloadMoreChart = dataChartMore.data.map(item => ({
      [item.title]: item.words_cloud,
    }))

    const result = payloadMoreChart.reduce((acc, cur) => {
      return { ...acc, ...cur }
    }, {})

    switch (values.analysis_menu) {
      case '0':
        return responseDataWordCloud.option1

      case '1':
        return {
          ...responseDataWordCloud.option2,
          words_cloud: {
            ...responseDataWordCloud.option2?.words_cloud,
            ...result,
          },
        }

      case '2':
        return responseDataWordCloud.option3

      default:
        return {}
    }
  }, [
    dataChartMore.data,
    responseDataWordCloud.option1,
    responseDataWordCloud.option2,
    responseDataWordCloud.option3,
    values.analysis_menu,
  ])

  const fetchWordLesChart = useCallback(
    async (id: number) => {
      const params = {
        company_id: +id,
        type_of_process: +values.targetDocument,
        year: +values.targetYear,
        ...(values.quarters && { quarter: +values.quarters }),
      }
      try {
        const { data, hashed_data } =
          await companyServices.getWordLesChart(params)

        setDataChart(prev => ({
          ...prev,
          wordLesChart: {
            dataWordCloud: data?.word_cloud ?? [],
            dataEchart: data?.les_miserable ?? [],
            hashed_data: hashed_data ?? '',
          },
          dataListWordCloud: {
            data: [],
            hashed_data: '',
          },
          dataTimeLine: {
            data: [],
            hashed_data: '',
          },
          loading: false,
        }))

        setResponseDataWordCloud(prev => ({ ...prev, option1: data }))
      } catch (error) {
        handleResetDataChart()
      }
    },
    [values.quarters, values.targetDocument, values.targetYear],
  )

  const fetchWordCategoryChart = useCallback(
    async (id: number) => {
      const params = {
        company_id: +id,
        type_of_process: +values.targetDocument,
        year: +values.targetYear,
        ngram: +values.number_of_word,
        ...(values.quarters && { quarter: +values.quarters }),
      }
      try {
        const { data, hashed_data } =
          await companyServices.getWordCategoryChart(params)

        const convertData2 = Object.keys(data.words_cloud).map(key => ({
          title: key,
          words_cloud: data.words_cloud[key],
        }))

        const arrCompany = data.companies_ability_to_compare.map(o => ({
          label: Object.keys(o)[0],
          value: o[Object.keys(o)[0]],
        }))

        setMultiCompanyOptions(arrCompany)
        setDataChart(prev => ({
          ...prev,
          wordLesChart: {
            dataWordCloud: [],
            dataEchart: [],
            hashed_data: '',
          },
          dataListWordCloud: {
            data: convertData2,
            hashed_data: hashed_data ?? '',
          },
          dataTimeLine: {
            data: [],
            hashed_data: '',
          },
          loading: false,
        }))

        setResponseDataWordCloud(prev => ({ ...prev, option2: data }))
      } catch (error) {
        handleResetDataChart()
      }
    },
    [
      values.number_of_word,
      values.quarters,
      values.targetDocument,
      values.targetYear,
    ],
  )

  const fetchWordTimeLineChart = useCallback(
    async (id: number) => {
      const params = {
        company_id: +id,
        type_of_process: +values.targetDocument,
        year: +values.targetYear,
        previous_times: +values.term,
        ngram: +values.number_of_word,
        ...(values.quarters && { quarter: +values.quarters }),
      }
      try {
        const { data: data3, hashed_data: hashed_data_3 } =
          await companyServices.getWordTimeLineChart(params)

        const convertData = data3.sort((a, b) => {
          if (values.sort_by === 1) {
            return a.index - b.index
          } else {
            return b.index - a.index
          }
        })

        setDataChart(prev => ({
          ...prev,
          wordLesChart: {
            dataWordCloud: [],
            dataEchart: [],
            hashed_data: '',
          },
          dataListWordCloud: {
            data: [],
            hashed_data: '',
          },
          dataTimeLine: {
            data: convertData,
            hashed_data: hashed_data_3 ?? '',
          },
          loading: false,
        }))

        setResponseDataWordCloud(prev => ({ ...prev, option3: data3 }))
      } catch (error) {
        handleResetDataChart()
      }
    },
    [
      values.number_of_word,
      values.quarters,
      values.sort_by,
      values.targetDocument,
      values.targetYear,
      values.term,
    ],
  )

  const fetchDataChart = useCallback(async () => {
    if (!id || !isExplanation) return

    setDataChart(prev => ({
      ...prev,
      wordLesChart: {
        dataWordCloud: [],
        dataEchart: [],
        hashed_data: '',
      },
      dataListWordCloud: {
        data: [],
        hashed_data: '',
      },
      dataTimeLine: {
        data: [],
        hashed_data: '',
      },
      loading: true,
    }))

    switch (values.analysis_menu) {
      case '0':
        fetchWordLesChart(+id)
        break
      case '1':
        fetchWordCategoryChart(+id)
        break
      case '2':
        fetchWordTimeLineChart(+id)

        break

      default:
        break
    }
  }, [
    fetchWordCategoryChart,
    fetchWordLesChart,
    fetchWordTimeLineChart,
    id,
    isExplanation,
    values.analysis_menu,
  ])

  const handleOffSwitch = () => {
    setValueSwitch(false)
    setValueExplanation(prev => ({
      ...prev,
      loading: false,
      data: '',
      done: true,
    }))

    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()
  }

  const fetchMoreWordCloud = useCallback(async () => {
    if (!id || !values.multi_company) return

    try {
      setDataChartMore(prev => ({
        ...prev,
        loading: true,
        data: [],
        hashed_data: '',
      }))

      const params = {
        company_id: +id,
        type_of_process: +values.targetDocument,
        year: +values.targetYear,
        quarter: +values.quarters,
        ngram: +values.number_of_word,
      }

      const arrIdCompany = values.multi_company.map(obj => obj.value)

      const arrCompany = await Promise.all(
        arrIdCompany.map(
          async item =>
            await companyServices.getWordCloudMoreChart({
              ...params,
              compare_to: +item,
            }),
        ),
      )

      let newData: {
        title: string
        words_cloud: { text: string; value: number }[]
      }[] = []
      let hashed_data = ''

      arrCompany.forEach(item => {
        let title = Object.keys(item.data)[0]

        let data = item.data[title].map(word => ({
          text: word.text,
          value: word.value,
        }))

        hashed_data = hashed_data + item.hashed_data
        newData = [...newData, { title: title, words_cloud: data }]
      })

      setDataChartMore(prev => ({
        ...prev,
        data: newData,
        loading: false,
        hashed_data: hashed_data,
      }))
    } catch (error) {
      setDataChartMore(prev => ({
        ...prev,
        loading: false,
        data: [],
        hashed_data: '',
      }))
    }
  }, [
    id,
    values.multi_company,
    values.number_of_word,
    values.quarters,
    values.targetDocument,
    values.targetYear,
  ])

  const handleSwitch = async () => {
    if (!companyName) return

    if (valueSwitch) {
      handleOffSwitch()
    } else {
      if (valueExplanation.loading || isNotWordmap || !isExplanation) return

      setValueSwitch(true)
      setValueExplanation(prev => ({
        ...prev,
        loading: true,
        data: '',
        done: false,
      }))
    }
  }

  const handleResetDataChart = () => {
    setDataChart(prev => ({
      ...prev,
      wordLesChart: {
        dataEchart: [],
        dataWordCloud: [],
        hashed_data: '',
      },
      dataListWordCloud: {
        data: [],
        hashed_data: '',
      },
      dataTimeLine: {
        data: [],
        hashed_data: '',
      },
      loading: false,
    }))
    setDataChartMore(prev => ({
      ...prev,
      data: [],
      loading: false,
      hashed_data: '',
    }))
  }

  const handleOpenZoom = (info: {
    isShowAll: boolean
    typeChart: string
    index: number | null
  }) => {
    setInfoModal(info)
    onOpenZoom()
  }

  const handleCloseZoom = () => {
    onCloseZoom()
  }

  useEffect(() => {
    fetchCompanyInfo()
  }, [fetchCompanyInfo])

  useEffect(() => {
    fetchMoreWordCloud()
  }, [fetchMoreWordCloud])

  useEffect(() => {
    fetchDataChart()
  }, [fetchDataChart])

  useEffect(() => {
    if (!accessToken || isNotWordmap || !valueSwitch) return

    const newSocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/api/v1/advisor/list_new_file/summary_image/ws_stream`,
    )

    const handleSocketOpen = async () => {
      console.log('WS connected')
      const messAuthen = {
        type: 'auth_req',
        data: { Authorization: `Bearer ${accessToken}` },
      }
      newSocket.send(JSON.stringify(messAuthen))

      let message = {
        type: 'stream_req',
        data: {
          type_image: fileType,
          type_of_process: +values.targetDocument,
          company: companyName,
          hashed_data: hashedDataChart,
          data: payloadSendWebsocket,
        },
      }

      newSocket.send(JSON.stringify(message))
    }

    const handleSocketMessage = (event: { data: string }) => {
      const objMess = JSON.parse(event.data)
      if (objMess?.done === 1) {
        setValueExplanation(prev => ({
          ...prev,
          data: prev.data.replace(/```/g, ''),
          done: true,
        }))
      } else {
        switch (objMess.type) {
          case 'stream_res':
            setValueExplanation(prev => ({
              ...prev,
              loading: false,
              data: (prev.data + objMess?.data).replace(/```html/g, ''),
            }))
            break
          default:
            break
        }
      }
    }

    const handleSocketClose = () => {
      console.log('WebSocket is disconnected')
    }

    newSocket.addEventListener('open', handleSocketOpen)
    newSocket.addEventListener('message', handleSocketMessage)
    newSocket.addEventListener('close', handleSocketClose)

    return () => {
      newSocket.removeEventListener('open', handleSocketOpen)
      newSocket.removeEventListener('message', handleSocketMessage)
      newSocket.removeEventListener('close', handleSocketClose)
      newSocket.close()
    }
  }, [
    accessToken,
    companyName,
    fileType,
    hashedDataChart,
    isNotWordmap,
    payloadSendWebsocket,
    valueSwitch,
    values.analysis_menu,
    values.targetDocument,
  ])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidthSize(document.documentElement.clientWidth)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    ...props,
    formik,
    values,
    dataChart,
    setFieldValue,
    displaySizeChart,
    setDataChart,
    multiCompanyOptions,
    dataChartMore,
    valueSwitch,
    setValueSwitch,
    handleSwitch,
    valueExplanation,
    setValueExplanation,
    companyName,
    handleOffSwitch,
    isNotWordmap,
    isZoom,
    handleOpenZoom,
    handleCloseZoom,
    infoModal,
    handleResetDataChart,
    setValues,
    containerChartHeight,
    containerChartWidth,
  }
}

import { Box, Flex, Text, Tooltip } from '@chakra-ui/react'
import React, { FC, useState } from 'react'
import { Button, TextOverflowTooltip } from 'components/fromAdvisor/elements'
import { Option } from 'types/conmon'
import { ArrowDown } from '../Icons'
import { StyledCheckbox } from '../CheckboxField'

type CustomMultiSelectProps = {
  options: Option[]
  placeholder: string
  defaultValue: Option[]
  setValue: (options: Option[]) => void
  labelTooltip: string
}

const MAX_COMPANY_COMPARE = 2

const CustomMultiSelect: FC<CustomMultiSelectProps> = ({
  options,
  placeholder,
  defaultValue,
  setValue,
  labelTooltip,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(defaultValue)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleCancel = () => {
    setIsOpen(false)
  }

  const isSelected = (option: Option) =>
    selectedOptions.map(item => item.value).includes(option.value)

  const handleOptionClick = (option: Option) => {
    const exsistOption = selectedOptions.find(opt => opt.value === option.value)
    if (exsistOption !== undefined) {
      const newValue = selectedOptions.filter(opt => opt.value !== option.value)
      setSelectedOptions(newValue)
    } else {
      if (selectedOptions.length >= MAX_COMPANY_COMPARE) return
      setSelectedOptions(prev => [...prev, option])
    }
  }

  const handleChangeMultiValue = () => {
    setValue(selectedOptions)
    handleCancel()
  }

  const isMaxValue = React.useMemo(
    () => selectedOptions.length >= MAX_COMPANY_COMPARE,
    [selectedOptions.length],
  )

  const isDisabled = (option: Option) => {
    if (!isMaxValue) return false
    return !selectedOptions.map(item => item.value).includes(option.value)
  }

  return (
    <Box
      h={10}
      margin={'0 auto'}
      maxW={300}
      minW={'150px'}
      position={'relative'}
    >
      <Tooltip
        hasArrow
        bgColor={'#E5F2FE'}
        borderRadius={'24px'}
        color="blue.900"
        fontSize={'14px'}
        h={'30px'}
        label={labelTooltip}
        lineHeight={'26px'}
        placement="top"
        px={4}
      >
        <Flex
          alignItems={'center'}
          backgroundColor={'#FEFEFE'}
          borderColor={'#E8ECEF'}
          borderRadius={'12px'}
          borderStyle={'solid'}
          borderWidth={'1px'}
          boxShadow="0px 5px 10px -5px #1417181A inset"
          cursor={'pointer'}
          h={10}
          justifyContent={'space-between'}
          paddingLeft={4}
          pr={1}
          py={2}
          onClick={toggleDropdown}
        >
          <Text
            color={'#141718'}
            fontSize={'14px'}
            lineHeight={'24px'}
            opacity={defaultValue.length > 0 ? 1 : 0.3}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            width={'256px'}
          >
            {defaultValue.length > 0
              ? defaultValue.map(item => item.label).join(', ')
              : placeholder}
          </Text>
          <ArrowDown fontSize="24px" />
        </Flex>
      </Tooltip>
      {isOpen && (
        <Box
          backgroundColor={'#FEFEFE'}
          borderRadius={'4px'}
          boxShadow="0px 33px 24px -17px #00000014, 0px 0px 25px 4px #00000008"
          left={-5.5}
          position={'absolute'}
          px={8}
          py={6}
          top={11.5}
          w={'344px'}
          zIndex={1000}
        >
          {options.length === 0 ? (
            <Flex justifyContent={'center'}>
              <Text>（選択なし）</Text>
            </Flex>
          ) : (
            <>
              <Text
                color={'black.100'}
                fontSize={'14px'}
                fontWeight={'bold'}
                lineHeight={'24px'}
                mb={3}
              >
                会社
              </Text>
              {isMaxValue && (
                <Text
                  color={'blue.900'}
                  fontSize={'14px'}
                  lineHeight={'24px'}
                  mb={3}
                >
                  {labelTooltip}
                </Text>
              )}
              <Flex
                backgroundColor="#F3F5F7"
                borderRadius={'12px'}
                direction={'column'}
                gap={4}
                maxHeight="300px"
                overflowY="auto"
                p={3}
              >
                {options.map((option, index) => (
                  <Flex
                    key={index}
                    alignItems={'center'}
                    gap={2}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <StyledCheckbox
                      isChecked={isSelected(option)}
                      isDisabled={isDisabled(option)}
                      value={option.value}
                      onChange={() => handleOptionClick(option)}
                    />
                    {isDisabled(option) ? (
                      <Text
                        color={'#141718'}
                        cursor={'not-allowed'}
                        fontSize={'14px'}
                        lineHeight={'24px'}
                        opacity={0.3}
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                        whiteSpace={'nowrap'}
                        width={'209px'}
                      >
                        {option.label}
                      </Text>
                    ) : (
                      <TextOverflowTooltip
                        color={'#141718'}
                        fontSize="14px"
                        isCursor={!isDisabled(option)}
                        opacity={isDisabled(option) ? '0.3' : '1'}
                        value={option.label}
                        onClick={() => handleOptionClick(option)}
                      />
                    )}
                  </Flex>
                ))}
              </Flex>
              <Flex direction={'column'} gap={4} mt={6}>
                <Button
                  backgroundColor={'blue.900'}
                  border={'1px solid'}
                  borderColor={'blue.900'}
                  color="#FEFEFE"
                  text="決定"
                  w={'full'}
                  onClick={handleChangeMultiValue}
                />
                <Button
                  backgroundColor={'#FEFEFE'}
                  border={'1px solid'}
                  borderColor={'blue.900'}
                  color="blue.900"
                  text="とじる"
                  w={'full'}
                  onClick={() => {
                    setSelectedOptions(defaultValue)
                    handleCancel()
                  }}
                />
              </Flex>
            </>
          )}
        </Box>
      )}
    </Box>
  )
}

export default CustomMultiSelect

import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { forwardRef } from 'react'
import { TTheme } from '../../../theme'

export type CheckboxProps = Omit<ChakraCheckboxProps, 'colorScheme' | 'size'>

/**
 * Checkbox component page.
 * @return {JSX.Element} Checkbox component.
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ ...props }, ref) => {
    const mergedProps: Omit<ChakraCheckboxProps, 'colorScheme'> = { ...props }

    return <StyledCheckbox ref={ref} {...mergedProps} />
  },
)

/**
 * @returns function that handle background style
 */
const bgStyle = ({
  isDisabled,
}: Pick<CheckboxProps, 'isDisabled'> & { theme: TTheme }) => {
  if (!isDisabled) {
    return css`
      &[aria-checked='true'],
      &[data-checked],
      &[data-checked] {
        background: #0084ff;
        border: none;
      }
    `
  }
  return null
}

const StyledCheckbox = styled(ChakraCheckbox)`
  .chakra-checkbox__control {
    width: ${props => props.theme.sizes[6]};
    height: ${props => props.theme.sizes[6]};
    border-width: ${props => props.theme.sizes[0.5]};
    background-color: ${props => props.theme.colors.white[50]};
    border-color: ${props => props.theme.colors.purple[250]};
    border-radius: ${props => props.theme.radii.md};
    ${bgStyle}

    &[data-checked][data-hover] {
      background: ${props => props.theme.colors.blue[900]};
    }

    &[data-checked]:hover {
      background: ${props => props.theme.colors.blue[900]};
    }
  }
`

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ROLE } from 'constant'
import { User } from './type'

type InitialState = {
  loggedInUser: boolean
  user: User | null
  isAdmin: boolean
}

const initialState: InitialState = {
  loggedInUser: false,
  user: null,
  isAdmin: false,
}
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /**
     * status login success
     */
    loginSuccess(state: InitialState) {
      state.loggedInUser = true
    },
    /**
     * data user login
     */
    userLogin(state: InitialState, action: PayloadAction<{ user: User }>) {
      state.user = action.payload.user
      state.isAdmin = action.payload.user.user_type === ROLE.ADMIN
    },
    /**
     * logout
     */
    userLogout(state: InitialState) {
      state.user = initialState.user
      state.loggedInUser = false
    },
    userUpdateAgentGuide(
      state: InitialState,
      action: PayloadAction<{ user: User }>,
    ) {
      state.user = action.payload.user
    },
  },
})

export default authSlice

export const { loginSuccess, userLogin, userLogout, userUpdateAgentGuide } =
  authSlice.actions

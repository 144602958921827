import {
  Box,
  Text,
  Divider,
  FormLabel,
  Flex,
  CircularProgress,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { InputField, RadioGroupField } from 'components/atoms'
import { PenLight, TrashIcon } from 'components/atoms/Icons'
import { Button } from 'components/fromAdvisor/elements/Button'
import { ModalConfirm } from 'components/molecules'
import { ADMIN_ROUTES, ROLE, ROUTES } from 'constant'
import { FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { userAdminServices } from 'services'
import { BodyCreateUser } from 'services/userServices'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { setBreadcrumb } from 'context/Breadcrumb/breadcrumb.slice'
import { auth } from 'context/Auth/auth.selector'
import { userHasLimitedRights } from '../UserList'
import {
  MESSAGE_USER,
  initialValuesUser,
  userRegistrationSchema,
} from './validation'

/**
 * UserRegistration Page
 * @constructor
 */
export const UserRegistration: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const toast = useToast()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [userDetail, setUserDetail] = useState<BodyCreateUser>()
  const [loading, setLoading] = useState(false)

  const userLogin = useSelector(auth)

  const isRestrictUserRights = userHasLimitedRights.includes(
    userLogin?.email ?? '',
  )

  /**
   *
   */
  const handleCreateUser = async (values: BodyCreateUser) => {
    const urlSendMail = `${process.env.REACT_APP_EMAIL_URL}${
      values.user_type === ROLE.ADMIN
        ? ADMIN_ROUTES.PASSWORD_SETTING
        : ROUTES.PASSWORD_SETTING
    }?`

    setIsLoading(true)
    try {
      if (id) {
        const { data } = await userAdminServices.updateUser(id, values, {
          call_back: urlSendMail,
        })
        setUserDetail(data)
        navigate(`${ADMIN_ROUTES.USER_DETAIL}/${id}`)
      } else {
        await userAdminServices.createUser(values, { call_back: urlSendMail })
        navigate(ADMIN_ROUTES.USER_LIST)
      }
      setIsLoading(false)

      toast({
        status: 'success',
        description: id ? MESSAGE_USER.UPDATE_USER : MESSAGE_USER.CREATE_USER,
        position: 'top',
      })
    } catch (error: any) {
      setIsLoading(false)
      toast({
        status: 'error',
        description: error.response.data?.detail,
        position: 'top',
      })
    }
  }

  const formik = useFormik<BodyCreateUser>({
    initialValues: initialValuesUser,
    validationSchema: userRegistrationSchema,
    enableReinitialize: true,
    /**
     *
     */
    async onSubmit(values: BodyCreateUser) {
      handleCreateUser({
        ...values,
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        name: values.first_name + values.last_name,
      })
    },
  })
  const {
    setFieldValue,
    handleSubmit,
    values,
    resetForm,
    errors,
    setValues,
    dirty,
    isValid,
  } = formik

  /**
   *
   */
  const getUserDetail = useCallback(async () => {
    if (!id) return
    try {
      setLoading(true)
      const { data } = await userAdminServices.getUsertDetail(id)
      const { first_name, last_name, furigana_name_first, furigana_name_last } =
        data
      setUserDetail(data)
      setValues(data)
      dispatch(
        setBreadcrumb([
          {
            text: `${first_name} ${last_name}（${furigana_name_first}${furigana_name_last}）`,
            link: '',
          },
        ]),
      )
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      toast({
        status: 'error',
        description: error?.response?.data?.message,
        position: 'top',
      })
    }
  }, [dispatch, id, setValues, toast])

  useEffect(() => {
    getUserDetail()
  }, [getUserDetail])

  /**
   * @returns function that handle remove prompt
   */
  const handleRemoveUser = useCallback(async () => {
    if (!id) {
      return
    }
    try {
      await userAdminServices.removeUser([+id])
      onClose()
      toast({
        status: 'success',
        description: MESSAGE_USER.DELETE_USER,
        position: 'top',
      })
      navigate(ADMIN_ROUTES.USER_LIST)
    } catch (error: any) {
      toast({
        status: 'error',
        description: error.response.data?.detail,
        position: 'top',
      })
    }
  }, [id, navigate, onClose, toast])

  const isDisable = location.pathname.includes('user/')

  if (loading) {
    return (
      <Flex
        alignItems="center"
        bg="gray.600"
        h="calc(100vh - 88px)"
        justifyContent="center"
        w="100%"
      >
        <CircularProgress isIndeterminate color="blue.900" size={10} />
      </Flex>
    )
  }
  return (
    <FormikProvider value={formik}>
      <Box
        bgColor="white.50"
        borderRadius={4}
        minHeight="calc(100vh - 88px)"
        padding="24px 32px"
        w="100%"
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          paddingBottom="16px"
        >
          {location.pathname.includes('user-registration') && (
            <Text
              fontSize="24px"
              fontWeight="700"
              letterSpacing="-0.2px"
              lineHeight="40px"
              minW="158px"
              paddingBottom="16px"
            >
              新規ユーザー登録
            </Text>
          )}
          {!location.pathname.includes('user-registration') && (
            <Text
              color="#6C7275"
              fontSize="14px"
              fontWeight="400"
              height="40px"
              lineHeight="40px"
              minW="158px"
            >
              登録日：
              {moment(values.created_at).format('YYYY/MM/DD')}
            </Text>
          )}
          {location.pathname.includes('user/') && (
            <Button
              backgroundColor="white.50"
              border="1px solid #0084FF"
              borderRadius="12px"
              color="blue.900"
              fontSize="14px"
              fontWeight="bold"
              height="40px"
              leftIcon={<PenLight />}
              text="編集する"
              width="116px"
              onClick={() => navigate(`${ADMIN_ROUTES.USER_EDIT}/${id}`)}
            />
          )}
        </Box>
        <Divider color="#E8ECEF" />
        <Box display="flex" flexDirection="column" gap="32px" marginTop="24px">
          <RadioGroupField
            disable={isDisable || isRestrictUserRights}
            name="user_type"
            options={[
              { value: 'admin', label: '管理者' },
              { value: 'advisor', label: '一般ユーザー' },
            ]}
            onChange={(e: string | number) => setFieldValue('user_type', e)}
          />
          <Box display="flex" gap="24px">
            <Box>
              <FormLabel
                fontSize="14px"
                fontWeight="700"
                lineHeight="24px"
                margin="0 0 8px 0"
              >
                名前
              </FormLabel>
              <Box display="flex" gap="8px">
                <InputField
                  isFocus
                  disabled={isDisable}
                  error={Boolean(errors.first_name)}
                  errorText={errors.first_name}
                  height="48px"
                  maxWidth="167px"
                  name="first_name"
                  onChange={e => {
                    setFieldValue('first_name', e.target.value)
                  }}
                />
                <InputField
                  isFocus
                  disabled={isDisable}
                  error={Boolean(errors.last_name)}
                  errorText={errors.last_name}
                  height="48px"
                  maxWidth="167px"
                  name="last_name"
                  onChange={e => {
                    setFieldValue('last_name', e.target.value)
                  }}
                />
              </Box>
            </Box>
            <Box>
              <FormLabel
                fontSize="14px"
                fontWeight="700"
                lineHeight="24px"
                margin="0 0 8px 0"
              >
                フリガナ
              </FormLabel>
              <Box display="flex" gap="8px">
                <InputField
                  isFocus
                  disabled={isDisable}
                  error={Boolean(errors.furigana_name_first)}
                  errorText={errors.furigana_name_first}
                  height="48px"
                  maxWidth="167px"
                  name="furigana_name_first"
                  onChange={e => {
                    setFieldValue('furigana_name_first', e.target.value)
                  }}
                />
                <InputField
                  isFocus
                  disabled={isDisable}
                  error={Boolean(errors.furigana_name_last)}
                  errorText={errors.furigana_name_last}
                  height="48px"
                  maxWidth="167px"
                  name="furigana_name_last"
                  onChange={e => {
                    setFieldValue('furigana_name_last', e.target.value)
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box display="flex" gap="24px">
            <InputField
              isFocus
              disabled={isDisable}
              error={Boolean(errors.department)}
              errorText={errors.department}
              height="48px"
              label="部署"
              name="department"
              sizeLabel="14px"
              width="343px"
              onChange={e => setFieldValue('department', e.target.value)}
            />
            <InputField
              isFocus
              disabled={isDisable}
              error={Boolean(errors.position)}
              errorText={errors.position}
              height="48px"
              label="役職"
              name="position"
              sizeLabel="14px"
              width="343px"
              onChange={e => setFieldValue('position', e.target.value)}
            />
          </Box>
          <InputField
            isFocus
            disabled={isDisable}
            error={Boolean(errors.email)}
            errorText={errors.email}
            height="48px"
            label="メールアドレス"
            name="email"
            sizeLabel="14px"
            width="343px"
            onChange={e => setFieldValue('email', e.target.value)}
          />
        </Box>
        <Box display="flex" gap="16px" justifyContent="end" marginTop="24px">
          {isDisable ? (
            <Button
              backgroundColor="white.50"
              border="1px solid #6C7275"
              borderRadius="12px"
              color="#6C7275"
              fontSize="14px"
              fontWeight={'bold'}
              height="40px"
              isDisabled={isRestrictUserRights}
              leftIcon={<TrashIcon />}
              text="このユーザーを削除する"
              width="230px"
              onClick={onOpen}
            />
          ) : (
            <>
              <Button
                backgroundColor="#FEFEFE"
                border="1px solid #0084FF"
                borderRadius="12px"
                color="#0084FF"
                height="48px"
                text="キャンセル"
                width="127px"
                onClick={() => {
                  if (id) {
                    resetForm({ values: userDetail })
                  } else {
                    resetForm()
                  }
                  navigate(-1)
                }}
              />
              <Button
                backgroundColor="#0084FF"
                border="1px solid #0084FF"
                borderRadius="12px"
                color="white.50"
                height="48px"
                isDisabled={!_.isEmpty(errors) || !dirty || !isValid}
                isLoading={isLoading}
                text="登録してIDを発行する"
                width="206px"
                onClick={() => handleSubmit()}
              />
            </>
          )}
        </Box>
        <ModalConfirm
          isKeepAll
          isOpen={isOpen}
          title={`このユーザーは削除され、\n
          ログインできなくなります。\n
          よろしいですか？`}
          onClose={onClose}
          onSubmit={handleRemoveUser}
        />
      </Box>
    </FormikProvider>
  )
}

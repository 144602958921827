import { FormikErrors } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { companyServices } from 'services'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Option } from 'types/conmon'
import { FormValues } from '../hook'

export type FieldWordMapWrapperProps = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormValues>>
  setValues: (
    values: React.SetStateAction<FormValues>,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormValues>>
  values: FormValues
  handleResetDataChart: () => void
  setValueSwitch: React.Dispatch<React.SetStateAction<boolean>>
  setValueExplanation: React.Dispatch<
    React.SetStateAction<{
      loading: boolean
      data: string
      done: boolean
    }>
  >
  handleOffSwitch: () => void
}

export const useFieldWordMapWrapper = (props: FieldWordMapWrapperProps) => {
  const { id } = useParams()
  const {
    values,
    setValues,
    handleResetDataChart,
    setValueSwitch,
    setValueExplanation,
  } = props
  const [yearQuarters, setYearQuaters] = useState<{ [key: string]: number[] }>(
    {},
  )

  const reportYearsOptions = useMemo(() => {
    if (isEmpty(yearQuarters)) return []
    return Object.keys(yearQuarters).map(o => ({
      label: `${o}年度`,
      value: `${o}`,
    }))
  }, [yearQuarters])

  const reportQuartersOptions = useMemo(() => {
    if (!(values.targetYear && yearQuarters)) return []
    return (yearQuarters[values.targetYear] || []).map(o => ({
      label: `第${o}四半期`,
      value: `${o}`,
    }))
  }, [values.targetYear, yearQuarters])

  const termOptions: Option[] = useMemo(() => {
    if (Object.keys(yearQuarters).length === 0) return []

    if (!['2', '5'].includes(values.targetDocument)) {
      if (!values.targetYear || !values.quarters) return []
    } else {
      if (!values.targetYear) return []
    }

    let total = 0
    let stop = false

    if (!['2', '5'].includes(values.targetDocument)) {
      Object.keys(yearQuarters).forEach(item => {
        if (stop) return

        if (values.targetYear === item) {
          let indexQuarter = yearQuarters[values.targetYear].findIndex(
            val => val === +values.quarters,
          )
          total += indexQuarter + 1
          stop = true
          return
        } else {
          total += yearQuarters[item].length
        }
      })
    } else {
      Object.keys(yearQuarters).forEach(item => {
        if (stop) return

        if (values.targetYear === item) {
          let indexYear = Object.keys(yearQuarters).findIndex(
            val => val === item,
          )
          total += indexYear + 1
          stop = true
          return
        }
      })
    }

    return Array.from(Array(total).keys()).map(o => ({
      label: `${o + 1}期分`,
      value: o + 1,
    }))
  }, [values.quarters, values.targetDocument, values.targetYear, yearQuarters])

  const handleResetForm = (
    e:
      | {
          label: string
          value: string | number
        }
      | undefined,
  ) => {
    if (!e || e.value === values.analysis_menu) return

    setValues({
      analysis_menu: String(e?.value),
      targetYear: '',
      targetDocument: '',
      quarters: '',
      display_size: '0',
      number_of_word: '2',
      term: '',
      search_key: '',
      multi_company: [],
      sort_by: 1,
    })
    handleResetDataChart()
    setValueSwitch(false)
    setValueExplanation(prev => ({
      ...prev,
      loading: false,
      data: '',
      done: true,
    }))
    setYearQuaters({})
  }

  const fetchReportYearQuarters = useCallback(async () => {
    try {
      if (!id || !values.targetDocument) return

      const { data } = await companyServices.getReportYearQuarters({
        company_id: +id,
        type_of_process: +values.targetDocument,
      })

      if (isEmpty(data)) return

      setYearQuaters({ ...data })
    } catch (error) {
      console.log('error', error)
    }
  }, [id, values.targetDocument])

  useEffect(() => {
    fetchReportYearQuarters()
  }, [fetchReportYearQuarters])

  return {
    ...props,
    handleResetForm,
    setYearQuaters,
    reportYearsOptions,
    reportQuartersOptions,
    termOptions,
  }
}

import { useField } from 'formik'
import { FC, useRef } from 'react'
import { Flex, FormControl, FormLabel, useTheme, Text } from '@chakra-ui/react'
import Select, { MenuPlacement } from 'react-select'
import { Option } from 'types/conmon'
import { ArrowDown, QuestionCircle } from '../Icons'

export type SelectFieldProps = {
  name: string
  placeholder?: string
  label?: string
  height?: string
  width?: string
  maxWidth?: string
  minHeight?: string
  error?: boolean | undefined
  errorText?: any
  options: Option[]
  handleScrollBottom?: () => void
  onChange?: (newValue?: { label: string; value: string | number }) => void
  valueOption?: { label: string; value: string | number } | null
  isDisabled?: boolean
  iconLable?: boolean
  colorLabel?: string
  sizeLabel?: string
  fontWeight?: string | number
  fontSize?: string
  fontWeightLabel?: string
  test?: boolean
  subLabel?: string
  isSearchable?: boolean
  onFocus?: () => void
  isInForm?: boolean
  sizePlaceholder?: string
  heightListOptions?: string
  isResetValue?: boolean
  isMulti?: boolean
  isCenter?: boolean
  menuPlacement?: MenuPlacement
  onInputChange?: (key: string) => void
  isLoading?: boolean
}

/**
 * SelectField component
 * @constructor
 */
export const SelectField: FC<SelectFieldProps> = ({
  name,
  options,
  label,
  placeholder,
  height,
  width,
  maxWidth,
  minHeight,
  handleScrollBottom,
  onChange,
  onFocus,
  isDisabled = false,
  iconLable = false,
  colorLabel,
  sizeLabel,
  error,
  errorText,
  fontWeight = 'normal',
  fontSize = '14px',
  fontWeightLabel,
  valueOption,
  subLabel,
  isSearchable = false,
  sizePlaceholder = '16px',
  heightListOptions = '312px',
  isResetValue = false,
  isMulti = false,
  isInForm = true,
  isCenter = false,
  menuPlacement = 'auto',
  onInputChange,
  isLoading = false,
}) => {
  const [field, meta, helpers] = useField(name)
  const { value, touched } = meta
  const { setTouched } = helpers
  const theme = useTheme()
  const selectRef = useRef<any>(null)

  /**
   * customDropdownIndicator
   */
  const customDropdownIndicator = () => (
    <Flex
      alignItems="center"
      display={isDisabled ? 'none' : 'block'}
      justifyContent="center"
    >
      <ArrowDown fontSize="24px" />
    </Flex>
  )

  const customOption = isResetValue
    ? [{ value: '', label: '（選択なし）' }, ...options]
    : options

  return (
    <FormControl maxWidth={maxWidth} width={width}>
      {label && (
        <FormLabel
          alignItems="center"
          color={colorLabel}
          display="flex"
          fontSize={sizeLabel}
          fontWeight={fontWeightLabel ? fontWeightLabel : '700'}
          gap="8px"
          lineHeight="24px"
          margin="0 0 8px 0"
        >
          <Flex>
            {label}
            {subLabel && <Text color={'gray.800'}>{subLabel}</Text>}
            {iconLable && <QuestionCircle />}
          </Flex>
        </FormLabel>
      )}
      <Select
        ref={selectRef}
        components={{
          DropdownIndicator: customDropdownIndicator,
        }}
        isClearable={false}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isRtl={false}
        isSearchable={isSearchable}
        loadingMessage={() => (
          <Text>読み込み中です。しばらくお待ちください。</Text>
        )}
        menuPlacement={menuPlacement}
        name={name}
        noOptionsMessage={() => <Text>データがありません。</Text>}
        options={customOption}
        placeholder={placeholder}
        styles={{
          singleValue: base => ({
            ...base,
            color: '#141718',
            fontSize: '14px',
            lineHeight: '24px',
            textAlign: isCenter ? 'center' : 'left',
          }),
          control: (base, { isDisabled }) => ({
            ...base,
            height: height,
            maxWidth: maxWidth,
            borderRadius: '12px',
            boxShadow: '0px 5px 10px -5px #1417181A inset',
            border:
              error && touched ? '1px solid #F75555' : '1px solid #E8ECEF',
            gap: '5px',
            color: isDisabled ? '#aaa ' : theme.colors.white[50],
            '&:hover': {
              borderStyle: 'solid',
              borderColor: theme.colors.blue[900],
              cursor: 'pointer',
              borderWidth: '1px',
            },
            background: isDisabled ? '#F3F5F7' : theme.colors.white[50],
            borderWidth: theme.space.px,
            width: width ?? theme.sizes.full,
            cursor: isDisabled ? 'not-allowed' : 'default',
            fontWeight: fontWeight,
            fontSize: fontSize,
            lineHeight: '24px',
            minHeight: minHeight,
          }),
          indicatorSeparator: base => ({
            ...base,
            display: 'none',
            height: height,
          }),
          valueContainer: base => ({
            ...base,
            paddingLeft: '10px',
          }),
          menu: styles => ({
            ...styles,
            margin: 0,
            background: `${theme.colors.black[300]}`,
            borderRadius: `${theme.radii['2xl']}`,
            zIndex: 3,
          }),
          option: (styles, props) => {
            return {
              ...styles,
              padding: `${theme.space[3]} ${theme.space[6]}`,
              fontWeight: props.isSelected
                ? theme.fontWeights.bold
                : theme.fontWeights.medium,
              letterSpacing: theme.letterSpacings.tighter,
              fontSize: theme.fontSizes.sm,
              lineHeight: theme.lineHeights.shorter,
              color: theme.colors.white[50],
              cursor: 'pointer',
              background: props.isSelected
                ? theme.colors.blue[900]
                : 'transparent',
              '&:hover': {
                background: theme.colors.blue[900],
                fontWeight: theme.fontWeights.bold,
              },
            }
          },
          placeholder: styles => ({
            ...styles,
            fontSize: sizePlaceholder ?? theme.fontSizes.md,
            color: theme.colors.black[100],
            opacity: 0.3,
            textAlign: isCenter ? 'center' : 'left',
          }),
          menuList: styles => ({
            ...styles,
            padding: `${theme.space[4]} 0`,
            maxHeight: heightListOptions,
            overflowY: 'auto',
          }),
        }}
        value={
          valueOption === null ? valueOption : valueOption ? valueOption : value
        }
        onBlur={isInForm ? field.onBlur : () => {}}
        onChange={(newValue: { label: string; value: string | number }) => {
          onChange && onChange(newValue)
          selectRef.current && selectRef.current.blur()
        }}
        onFocus={onFocus}
        onInputChange={(newValue: string) => {
          onInputChange && onInputChange(newValue)
        }}
        onKeyDown={e => {
          if (
            e.keyCode === 9 ||
            e.code === 'Space' ||
            e.keyCode === 27 ||
            e.code === 'Escape'
          )
            e.preventDefault()
        }}
        onMenuClose={() => (isInForm ? setTouched(true) : {})}
        onMenuScrollToBottom={handleScrollBottom}
      />
      {Boolean(error && touched) && (
        <Text
          color="#F75555"
          display="block"
          fontSize="14px"
          lineHeight="normal"
          marginTop="8px"
        >
          {errorText}
        </Text>
      )}
    </FormControl>
  )
}

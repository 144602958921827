/* eslint-disable jsx-a11y/iframe-has-title */
import { FC, memo } from 'react'
import { Box } from '@chakra-ui/react'

type NewsPdfProps = {
  file: string
  cunrentPage: string | number
}

/**
 * NewsPdf molecules
 * @constructor
 */
const NewsPdf: FC<NewsPdfProps> = ({ file, cunrentPage }) => {
  return (
    <Box height="calc( 100vh - 295px )" mt="6" p="0 50px 50px 50px">
      <iframe
        height="100%"
        src={`https://loanntreactplus.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
          file,
        )}#page=${cunrentPage}`}
        title=""
        width="100%"
      />
    </Box>
  )
}

export default memo(NewsPdf)
